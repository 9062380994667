import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AppComponent } from '@pixels/client/one-on-one/app.component';
import { initCapacitorAndAngular } from '@pixels/client/sentry/sentry-util';
import { isLocalHost } from '@pixels/client/utils/dev-tools';
import { ChatKoreanProjectMeta } from '@pixels/universal/model/apps/project-meta/chat-korean-project-meta';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (!isLocalHost() && environment.production) {
  initCapacitorAndAngular(ChatKoreanProjectMeta.clientSentryDsn ?? '', environment.phase);
}
if (environment.production) {
  enableProdMode();
} else {
// 개발 환경에서는 서비스 워커 삭제
//   unregisterServiceWorkers();
}

const isNative = Capacitor.isNativePlatform();
const isIOS = Capacitor.getPlatform() === 'ios';
const isAndroid = Capacitor.getPlatform() === 'android';
if (isNative) {
  const style = !isAndroid ? Style.Light : Style.Default;
  StatusBar.setStyle({ style });
  // if (isAndroid) {
  //   SplashScreen.hide();
  //   SplashScreen.show({ autoHide: true, fadeInDuration: 500, fadeOutDuration: 500, showDuration: 3000 });
  // }
}
bootstrapApplication(AppComponent, appConfig).catch(err => console.log(err));
